import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../Errors';
import SystemAlerts from '../Alerts';
import * as ROUTES from '../../constants/routes';
import TermsServicesPopup from '../Terms/termsPopUp';

/* 

RYAN: So, here's the function from the article.

function componentLoaderPromise(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoaderPromise(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}

*/

const HomePage = lazy(() => import('../Home'));

/*

RYAN: And here's the application of it. You would theoretically do this for all of the components. The 3 as the last parameter is the number of times you want it to try before giving up.
Not 100% sure that this will work, but worth a try.

const HomePage = lazy(( ) =>
componentLoaderPromise(( ) =>
import('../Home'),
3));

*/

const LoginPage = lazy(() => import('../SignIn'));
const UserDashboard = lazy(() => import('../Users'));
const CoursesDashboard = lazy(() => import('../Courses'));
const CompanyPageBase = lazy(() => import('../Companies'));
const EditCompanyForm = lazy(() => import('../Companies/editCompany'));
const CompanyForm = lazy(() => import('../Companies/companyForm'));
const SignUpFormBase = lazy(() => import('../SignUp'));
const ForgotPasswordForm = lazy(() => import('../SignIn/forgotPassword'));
const LoadingBlock = lazy(() => import('../Loading'));
const CoursePage = lazy(() => import('../Courses/viewPage'));
const ReviewCourse = lazy(() => import('../Courses/ReviewPage'));
const RetakeQuiz = lazy(() => import('../Courses/retakeQuiz'));
const Footer = lazy(() => import('../Footer'));
const StaffDashboard = lazy(() => import('../Staff'));
const CertAdminDashboard = lazy(() => import('../Certs'));
const TermsPage = lazy(() => import('../Terms'));
const RegisterFormBase = lazy(() => import('../SignUp/userRegister'));
const ResourceLibraryPageBase = lazy(() => import('../ResourceLibrary'));
const ClinicalLibraryPageBase = lazy(() => import('../ResourceLibrary/clinicIndex'));
const SignOut = lazy(() => import('../SignOut'));
const PromoSignUp = lazy(() => import('../SignUp/promoSignUp'));

const App = () => (
  <div className='app'>
    <Router>
      <div className='app-page-content'>
        <Suspense fallback={<LoadingBlock />}>
          <Switch>
            <ErrorBoundary>
              <Route exact path={ROUTES.LANDING} component={HomePage} />
              {/*<Route exact path={ROUTES.SIGN_UP} component={SignUpFormBase} />*/}
              <Route exact path={ROUTES.REGISTER_USER_TO_CLINIC} component={SignUpFormBase} />
              <Route exact path={ROUTES.LOGIN} component={LoginPage} />
              <Route exact path={ROUTES.HOME} component={HomePage} />
              <Route exact path={ROUTES.USERS} component={UserDashboard} />
              <Route exact path={ROUTES.COURSES} component={CoursesDashboard} />
              <Route exact path={ROUTES.COMPANY} component={CompanyPageBase} />
              <Route exact path={ROUTES.EDIT_COMPANY} component={EditCompanyForm} />
              <Route exact path={ROUTES.COMPANY_FORM} component={CompanyForm}/> 
             <Route exact path={ROUTES.FORGOTTEN_PASSWORD} component={ForgotPasswordForm} />
              <Route exact path={ROUTES.COURSE_VIEW} component={CoursePage} />
              <Route exact path={ROUTES.REVIEW_COURSE} component={ReviewCourse} />
              <Route exact path={ROUTES.REVIEW_QUIZID} component={RetakeQuiz} />
              <Route exact path={ROUTES.STAFF} component={StaffDashboard} />
              <Route exact path={ROUTES.TERMS} component={TermsPage} />
              <Route exact path={ROUTES.REG} component={RegisterFormBase} />
              <Route exact path={ROUTES.RESOURCE_LIBRARY} component={ResourceLibraryPageBase} />
              <Route exact path={ROUTES.CLINICAL_LIBRARY} component={ClinicalLibraryPageBase} />
              <Route exact path={ROUTES.CERTS} component={CertAdminDashboard} />
              <Route exact path={ROUTES.SIGN_OUT} component={SignOut} />
              <Route exact path={ROUTES.PROMO_SIGNUP} component={PromoSignUp} />
            </ErrorBoundary>
          </Switch>
        </Suspense>
      </div>
      <SystemAlerts />
      <TermsServicesPopup />
      <Footer />
    </Router>
  </div>
);

export default App;
